import React, { useState, useRef, useEffect } from 'react';
import './App.css';

// let backendUrl = 'https://drloretta-dev-backend.jennai.com';
// let backendUrl = 'https://wismo-backend.jennai.com';
let backendUrl = 'https://4430-2600-1700-4394-2d8f-4549-4d1e-5625-feab.ngrok-free.app';
// let backendUrl = 'http://localhost:1000';
let conversationId;
let username;

function App() {
    const [transcription, setTranscription] = useState('');
    const audioContextRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const silenceStartRef = useRef(null);
    const [micStream, setMicStream] = useState(null);

    const isRecordingRef = useRef(false);
    const recordingStartTimeRef = useRef(null);
    const chunksRef = useRef([]);

    const rmsThreshold = 0.02; // Adjust this threshold based on testing

    const audioPlayerRef = useRef(null);
    const isPlayingRef = useRef(false);
    const currentFetchRef = useRef(null);

    // New state variables for UI feedback
    const [status, setStatus] = useState('Initializing...');
    const [isListening, setIsListening] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        loadAndValidateConversation();
        initMicrophone();

        return () => {
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                audioContextRef.current.close();
            }
            if (micStream) {
                micStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const loadAndValidateConversation = async () => {
        const storedConversationId = localStorage.getItem('conversationId');
        const storedUsername = localStorage.getItem('username');
    
        try {
            let url = `${backendUrl}/api/validate_conversation?url=${encodeURIComponent(window.location.href)}`;
            if (storedConversationId && storedUsername) {
                url += `&conversationId=${storedConversationId}&username=${storedUsername}`;
            }
            const response = await fetch(url, {
                method: "get",
                headers: new Headers({
                  "ngrok-skip-browser-warning": "69420",
                }),
              });
            if (response.ok) {
                const data = await response.json();
                conversationId = data.conversationId;
                username = data.username;
    
                localStorage.setItem('conversationId', data.conversationId);
                localStorage.setItem('username', data.username);
            } else {
                console.error('Failed to validate conversation');
                localStorage.removeItem('conversationId');
                localStorage.removeItem('username');
                conversationId = null;
                username = null;
            }
        } catch (error) {
            console.error('Error validating conversation:', error);
        }
        console.log('conversationId:', conversationId);
        console.log('username:', username);
    };

    const initMicrophone = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                }
            });
            setMicStream(stream);
            setStatus('Ready to listen'); // Update status when microphone is initialized
        } catch (err) {
            console.error('Error accessing microphone:', err);
            setStatus('Error: Unable to access microphone'); // Update status on error
        }
    };

    useEffect(() => {
        if (micStream) {
            const setupAudioProcessing = () => {
                if (!audioContextRef.current) {
                    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                }

                const audioContext = audioContextRef.current;
                const source = audioContext.createMediaStreamSource(micStream);
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 2048;
                const dataArray = new Uint8Array(analyser.fftSize);

                source.connect(analyser);

                let rafId;
                const checkVolume = () => {
                    analyser.getByteTimeDomainData(dataArray);
                    let sum = 0;
                    for (let i = 0; i < dataArray.length; i++) {
                        const val = (dataArray[i] - 128) / 128;
                        sum += val * val;
                    }
                    const rms = Math.sqrt(sum / dataArray.length);

                    if (rms > rmsThreshold) {
                        if (!isRecordingRef.current) {
                            console.log('Detected speech, starting recording');
                            startRecording();
                        }
                        silenceStartRef.current = null;
                        
                        // Interrupt if playing audio
                        if (isPlayingRef.current) {
                            stopPlayingAudio();
                        }
                    } else {
                        if (isRecordingRef.current) {
                            if (!silenceStartRef.current) {
                                silenceStartRef.current = Date.now();
                            } else if (Date.now() - silenceStartRef.current > 1000) {
                                console.log('Detected silence for 1 second, stopping recording');
                                stopRecording();
                                silenceStartRef.current = null;
                            }
                        }
                    }

                    rafId = requestAnimationFrame(checkVolume);
                };

                checkVolume();

                return () => {
                    cancelAnimationFrame(rafId);
                    source.disconnect();
                    analyser.disconnect();
                };
            };

            const cleanup = setupAudioProcessing();
            return cleanup;
        }
    }, [micStream]);

    const startRecording = () => {
        console.log('Starting recording');
        
        // Abort any ongoing fetch request
        if (currentFetchRef.current) {
            console.log('Aborting ongoing fetch request');
            currentFetchRef.current.controller.abort();
            currentFetchRef.current = null;
        }
        
        // Stop any playing audio
        stopPlayingAudio();
    
        isRecordingRef.current = true;
        setIsListening(true); // Update UI state
        setStatus('Listening...'); // Update status
        recordingStartTimeRef.current = Date.now();
        chunksRef.current = [];
        const recorder = new MediaRecorder(micStream, { mimeType: 'audio/webm;codecs=opus' });
    
        recorder.ondataavailable = e => chunksRef.current.push(e.data);
        recorder.onstop = () => {
            const blob = new Blob(chunksRef.current, { type: 'audio/webm' });
            console.log("Recording complete. Blob size:", blob.size);
            sendAudioToBackend(blob);
        };
    
        mediaRecorderRef.current = recorder;
        recorder.start();
    };

    const stopRecording = () => {
        console.log('Stopping recording');
        isRecordingRef.current = false;
        setIsListening(false); // Update UI state
        setStatus('Processing...'); // Update status
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            const duration = Date.now() - recordingStartTimeRef.current;
            console.log("Recording stopped. Duration:", duration, "ms");
            mediaRecorderRef.current = null;
        }
    };

    const sendAudioToBackend = async (audioBlob) => {
        console.log("Sending audio to backend. Blob size:", audioBlob.size);
        console.log('conversationId', conversationId);
        console.log('username', username);
        const formData = new FormData();
        formData.append('audio', audioBlob, 'audio.webm');
        formData.append('conversation_id', conversationId);
        formData.append('username', username);
        console.log("FormData created:", formData.get('audio'));
    
        setIsWaiting(true); // Update UI state
        setStatus('Waiting for response...'); // Update status
    
        const controller = new AbortController();
        const signal = controller.signal;
    
        try {
            currentFetchRef.current = { promise: fetch(`${backendUrl}/api/upwards_talking_backend`, {
                method: 'POST',
                body: formData,
                signal: signal
            }), controller: controller };
    
            const response = await currentFetchRef.current.promise;
    
            if (response.ok) {
                console.log('Received response from backend');
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    const data = await response.json();
                    setTranscription(data.transcription);
                    setStatus('Ready to listen'); // Update status
                } else {
                    const arrayBuffer = await response.arrayBuffer();
                    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
                    playAudioBuffer(audioBuffer);
                }
            } else {
                console.error('Error from backend:', response.status, response.statusText);
                setStatus('Error: Failed to get response'); // Update status on error
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
                setStatus('Listening...'); // Update status when aborted
            } else {
                console.error('Fetch error:', error);
                setStatus('Error: Failed to communicate with server'); // Update status on error
            }
        } finally {
            setIsWaiting(false); // Update UI state
            currentFetchRef.current = null;
        }
    };

    const playAudioBuffer = (audioBuffer) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        
        audioPlayerRef.current = source;
        isPlayingRef.current = true;
        setIsPlaying(true); // Update UI state
        setStatus('Playing response...'); // Update status
        
        source.onended = () => {
            isPlayingRef.current = false;
            setIsPlaying(false); // Update UI state
            audioPlayerRef.current = null;
            setStatus('Ready to listen'); // Update status when finished playing
        };
        
        source.start();
        console.log('Started playing audio');
    };

    const stopPlayingAudio = () => {
        if (audioPlayerRef.current) {
            audioPlayerRef.current.stop();
            audioPlayerRef.current = null;
            isPlayingRef.current = false;
            setIsPlaying(false); // Update UI state
            setStatus('Ready to listen'); // Update status
            console.log('Stopped playing audio');
        }
    };

    return (
        <div>
            <div>
                <h2>Status: {status}</h2>
                <p>Listening: {isListening ? 'Yes' : 'No'}</p>
                <p>Waiting for response: {isWaiting ? 'Yes' : 'No'}</p>
                <p>Playing audio: {isPlaying ? 'Yes' : 'No'}</p>
            </div>
            <div>
                <p>Conversation ID: {conversationId}</p>
                <p>Username: {username}</p>
            </div>
        </div>
    );
}

export default App;